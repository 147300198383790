<template>
  <div class="user">
      <div class="line_one">
        <el-form inline :model="queryInfo" label-width="100px" ref="form">
        <el-form-item label="信息搜索:">
          <el-input v-model="queryInfo.key" placeholder="请输入信息名称，描述关键词搜索" clearable style="width:280px"/>
        </el-form-item>
        <el-form-item>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
         <el-form-item>
          <el-button class="btn" type="primary" @click="search()">搜索</el-button>
        </el-form-item>
      </el-form>
      </div>
      <div class="line_two">
        <div class="buttonArea">
          <div class="add" @click="goAdd()">
            <i class="el-icon-plus"></i>
            新增话题
          </div>
        </div>
        <el-table :data="dataUserList" style="width: 100%" class="data_list" v-loading="loading" :header-cell-style="getRowClass">
        <el-table-column type="index" label="序号" sortable width="80" align="center"/>
        <el-table-column label="信息图片" width="150" align="center">
          <template v-slot="{ row }">
            <div class="flex">         
              <img :src="row.messageImage" style="width:84px;height:50px"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="信息名称" prop="messageName" align="center" width="120"/>
        <el-table-column label="发布时间" prop="createTime" align="center" width="180" sortable/>
        <el-table-column label="已读" align="center" sortable width="120" prop="isReadCount">
          <template v-slot="{ row }">
            <div @click="goMessage(1,row.id)" class="colorOne">
              {{row.isReadCount > 999 ? '999+' : row.isReadCount}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="未读" align="center" sortable width="120" prop="isNotReadCount">
          <template v-slot="{ row }">
            <div @click="goMessage(0,row.id)" class="colorOne">
             {{row.isNotReadCount > 999 ? '999+' : row.isNotReadCount}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="信息描述" align="center" width="400px">
          <template v-slot='{ row }'>
            <textFlow :info="row.messageDescribe" :lineClamp='2' :hiddenBtn="true"></textFlow>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <div class="caoZuo">
              <div class="colorOne" style="margin-right:10px" @click="goAdd(row.id)">查看详情</div>
              <div class="colorOne red" @click="deleteOne(row.id)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.current"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
  </div>
</template>
<script>
import textFlow from '@/components/textflow.vue'
import { pagination } from '@/mixins/pagination'



import { getAllInfo, deleteInfo } from '@/api/infoPush.js'

const defaultQueryInfo = Object.freeze({
  current:   0,
  size: 10,
  key:''
})
export default {
  name: 'User',
  mixins: [pagination],
  components: { textFlow },
  data() {
    return {
      dataUserList: [],
      detailList: [],
      selectionArr: [],
      loading: false,
      dataList:{},
      queryInfo: { ...defaultQueryInfo }
    }
  },
  created() {
    this.search()
  },
  methods: {
    // 跳转至新增信息页面
    goAdd(id){
      if(id){
        this.$router.push({
          path:`/infoPush/addInfo?id=${id}`,
        })
      }else{
        this.$router.push({
          path:`/infoPush/addInfo`,
        })
      }
    },
    // 跳转至已读和未读信息页面
    goMessage(readStatus,messageId){
      this.$router.push({
        path: `/infoPush/readingMessage?readStatus=${readStatus}&messageId=${messageId}`,
      })
    },
    //重置查询条件
    reset() {
      this.queryInfo = {
        pageNum: 0,
        pageSize: 10,
      }
      this.search()
    },
    goDetail(row) {
      this.$router.push({
        path: '/userManagement/userDetail?id='+row.id,
      })
    },
    async search() {
      this.loading = true
      const { data: res } = await getAllInfo(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataUserList = res.data.list
        this.total = res.data.total
        this.dataUserList.map(item=>{
          if(item.messageImage) item.messageImage = item.messageImage.split(',')[0]
        })
        this.loading = false
      }
    },
    async deleteOne(id){
      this.$confirm(`确认删除所选话题吗 ?`, {
          type: 'warning'
      }).then(async() => {
        let idList = []
        idList.push(id)
        const { data: res } = await deleteInfo(idList)
        if (res.resultCode === 200) {
          this.$message.success(`删除成功`)
          this.search()
        } else {
          this.$message.error('删除失败，请重试。')
        }
        })
        .catch(() => {})
    },
  }
}
</script>

<style lang="less" scoped>
.colorOne{
  color: #7C71E1;
  cursor: pointer;
}
.user {
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
  .line_one{
    background: #ffffff;
    border-radius: 12px;
    padding-top: 20px;
  } 
  .line_two{
    background: #ffffff;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;
    .buttonArea{
      display: flex;
      margin-top: 20px;
      div{
        width: 100px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }
      .add{
        background: #7C71E1;
        color: #ffffff;
      }
    }
  }
  .caoZuo{
    display: flex;
    justify-content: center;
    .colorOne{
      font-size: 12px;
      color: #7C71E1;
      cursor: pointer;
    }
    .red{
      color: #FF2828;
    }
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
  .data_list {
    width: 100%;
    margin: 20px 0px 0px 15px;
  }
}

</style>
